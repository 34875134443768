import * as React from "react"

// iPad Pro landscape is 1366x1024, we want to show desktop layout for it
export function useIsMobile() {
  const [isMobile, setIsMobile] = React.useState<boolean | undefined>(undefined)

  React.useEffect(() => {
    const checkMobile = () => {
      // If width > 1024px and height > 1000px, treat as desktop
      if (window.innerWidth > 1024 && window.innerHeight > 1000) {
        return false;
      }
      // For all other cases, use 1024px as breakpoint
      return window.innerWidth < 1024;
    };

    const onChange = () => {
      setIsMobile(checkMobile())
    }

    window.addEventListener("resize", onChange)
    setIsMobile(checkMobile())
    return () => window.removeEventListener("resize", onChange)
  }, [])

  return !!isMobile
}
