"use client";

import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { X } from "lucide-react";
import { useRouter } from "next/navigation";

export interface Notification {
  id: string;
  creatorId: string;
  creatorName: string;
  creatorAvatar: string;
  message: string;
  timestamp: Date;
  read: boolean;
}

interface NotificationToastProps {
  notification: Notification;
  onClose: (id: string) => void;
  autoClose?: boolean;
  duration?: number;
}

export function NotificationToast({ 
  notification, 
  onClose, 
  autoClose = true, 
  duration = 5000 
}: NotificationToastProps) {
  const [isVisible, setIsVisible] = useState(true);
  const router = useRouter();

  useEffect(() => {
    if (autoClose) {
      const timer = setTimeout(() => {
        setIsVisible(false);
        setTimeout(() => onClose(notification.id), 300); // Wait for exit animation
      }, duration);

      return () => clearTimeout(timer);
    }
  }, [autoClose, duration, notification.id, onClose]);

  const handleClick = () => {
    router.push(`/chat/${notification.creatorId}`);
    onClose(notification.id);
  };

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          initial={{ opacity: 0, y: 50, scale: 0.95 }}
          animate={{ opacity: 1, y: 0, scale: 1 }}
          exit={{ opacity: 0, y: 20, scale: 0.95 }}
          transition={{ type: "spring", stiffness: 500, damping: 30 }}
          className="fixed bottom-[calc(4rem+env(safe-area-inset-bottom))] left-4 right-4 mx-auto md:px-0 md:right-4 md:left-auto z-50 w-auto md:w-full md:max-w-sm overflow-hidden md:rounded-lg bg-black/20 backdrop-blur-[40px] shadow-[0_8px_32px_rgba(0,0,0,0.2)] border border-white/10 md:bottom-4"
        >
          <div 
            className="p-3 md:p-4 cursor-pointer hover:bg-white/5 transition-colors"
            onClick={handleClick}
          >
            <div className="flex items-start gap-3 md:gap-4">
              <Avatar className="h-8 w-8 md:h-10 md:w-10 border border-white/20">
                <AvatarImage src={notification.creatorAvatar} />
                <AvatarFallback>
                  {notification.creatorName.slice(0, 2).toUpperCase()}
                </AvatarFallback>
              </Avatar>
              <div className="flex-1 space-y-0.5 md:space-y-1">
                <p className="text-xs md:text-sm font-medium text-white">
                  {notification.creatorName}
                </p>
                <p className="text-xs md:text-sm text-white/70">
                  {notification.message}
                </p>
                <p className="text-[10px] md:text-xs text-white/50">
                  {new Date(notification.timestamp).toLocaleTimeString()}
                </p>
              </div>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setIsVisible(false);
                  setTimeout(() => onClose(notification.id), 300);
                }}
                className="rounded-full p-1 hover:bg-white/10 transition-colors"
              >
                <X className="h-3 w-3 md:h-4 md:w-4 text-white/70" />
              </button>
            </div>
          </div>
          {/* Progress bar */}
          {autoClose && (
            <motion.div
              initial={{ width: "100%" }}
              animate={{ width: "0%" }}
              transition={{ duration: duration / 1000, ease: "linear" }}
              className="h-0.5 md:h-1 bg-white/20"
            />
          )}
        </motion.div>
      )}
    </AnimatePresence>
  );
} 