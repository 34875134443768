"use client";

import type { Metadata } from "next";
import { Inter, Space_Grotesk } from "next/font/google";
import "./globals.css";
import { AppSidebar } from "@/components/app-sidebar";
import { SidebarProvider, SidebarInset } from "@/components/ui/sidebar";
import { MobileNav } from "@/components/mobile-nav";
import { NotificationProvider } from "@/components/notifications/notification-provider";
import { TopBar } from "@/components/dashboard/top-bar";
import { usePathname } from "next/navigation";
import { useEffect } from "react";
import { RootProvider } from "@/components/providers/root-provider";
import { cn } from "@/lib/utils";

const inter = Inter({ subsets: ["latin"] });
const spaceGrotesk = Space_Grotesk({ subsets: ["latin"] });

export default function RootLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const pathname = usePathname();

  useEffect(() => {
    // Disable scroll restoration for chat pages
    if (pathname?.includes("/chat")) {
      // Disable browser's scroll restoration
      window.history.scrollRestoration = "manual";
      
      // Force scroll to top and prevent any movement
      window.scrollTo(0, 0);
      document.body.style.overflow = 'hidden';
      document.documentElement.style.overflow = 'hidden';
      document.documentElement.style.scrollBehavior = 'auto';
    } else {
      // Re-enable scrolling for other pages
      document.body.style.overflow = 'auto';
      document.documentElement.style.overflow = 'auto';
    }

    return () => {
      // Cleanup when component unmounts
      document.body.style.overflow = 'auto';
      document.documentElement.style.overflow = 'auto';
    };
  }, [pathname]);

  return (
    <html lang="en">
      <body className={`${inter.className} relative h-screen overflow-hidden bg-transparent`}>
        <RootProvider>
          <div className={pathname?.includes("/chat") ? "overflow-hidden h-screen" : "h-screen"}>
            <div className="gradient-bg">
              <div className="gradients-container">
                <div className="g1"></div>
                <div className="g2"></div>
                <div className="g3"></div>
                <div className="g4"></div>
                <div className="g5"></div>
              </div>
            </div>
            <NotificationProvider>
              <SidebarProvider>
                <AppSidebar />
                <SidebarInset className="flex flex-col h-screen bg-transparent">
                  <main className="flex-1 bg-transparent overflow-hidden">
                    <div className="relative h-full flex flex-col">
                      <div className="fixed top-0 right-0 left-0 md:top-4 md:right-4 md:left-[calc(var(--sidebar-width)_+_1rem)] z-50 transition-[left] duration-200 ease-linear peer-data-[state=collapsed]:md:left-[calc(var(--sidebar-width-icon)_+_1rem)]">
                        <TopBar />
                      </div>
                      <div className={cn(
                        "container mx-auto flex-1 px-0 md:px-4 overflow-hidden",
                        pathname?.includes("/chat") ? "lg:container max-w-none lg:max-w-[80rem] lg:px-4" : ""
                      )}>
                        {children}
                      </div>
                    </div>
                  </main>
                  <MobileNav />
                </SidebarInset>
              </SidebarProvider>
            </NotificationProvider>
          </div>
        </RootProvider>
      </body>
    </html>
  );
}
