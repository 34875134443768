"use client"

import { configureStore, combineReducers } from '@reduxjs/toolkit';
import authReducer, { clearToken } from './authSlice';
import sectionQueryReducer from './activeSectionQuerySlice';
import tierReducer from './tierSlice';
import imageCarouselReducer from './imageCarouselSlice';
import { persistReducer, persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import { TypedUseSelectorHook, useSelector as useReduxSelector } from 'react-redux';

// Define the root reducer
const rootReducer = combineReducers({
    auth: authReducer,
    activeSectionQuery: sectionQueryReducer,
    tier: tierReducer,
    imageCarousel: imageCarouselReducer,
});

let store: ReturnType<typeof makeStore> | undefined;
let persistor: ReturnType<typeof persistStore> | undefined;

function makeStore() {
    if (typeof window === 'undefined') {
        return configureStore({
            reducer: rootReducer,
            middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
        });
    }

    // Import storage dynamically on client side
    const storage = require('redux-persist/lib/storage').default;
    
    const persistConfig = {
        key: 'root',
        version: 1,
        storage,
        whitelist: ['auth'], // only persist auth
        blacklist: [], // don't persist these
    };

    const persistedReducer = persistReducer(persistConfig, rootReducer);

    const _store = configureStore({
        reducer: persistedReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: {
                    ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
                },
            }),
    });

    return _store;
}

// Initialize store and persistor
export function getStoreAndPersistor() {
    if (typeof window === 'undefined') {
        return {
            store: makeStore(),
            persistor: undefined
        };
    }

    if (!store) {
        store = makeStore();
        persistor = persistStore(store);
    }

    return { store, persistor };
}

// Handle 401 errors
export function handle401Error() {
    if (store) {
        // Clear the auth state
        store.dispatch(clearToken());
        // Clear persisted state
        clearPersistedState();
    }
}

// Clear persisted state
export function clearPersistedState() {
    if (typeof window !== 'undefined') {
        const storage = require('redux-persist/lib/storage').default;
        storage.removeItem('persist:root');
    }
}

// Infer the type of makeStore
export type AppStore = ReturnType<typeof makeStore>;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<AppStore['getState']>;
export type AppDispatch = AppStore['dispatch'];

export const useAppSelector: TypedUseSelectorHook<RootState> = useReduxSelector; 