import { useEffect, useState } from "react";
import { Dialog, DialogContent, DialogTitle } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { ChevronLeft, ChevronRight, X } from "lucide-react";
import { useDispatch } from "react-redux";
import { setCarouselOpen } from "@/lib/store/imageCarouselSlice";

interface ImageCarouselProps {
  images: string[];
  initialIndex: number;
  isOpen: boolean;
  onClose: () => void;
}

export function ImageCarousel({ images, initialIndex, isOpen, onClose }: ImageCarouselProps) {
  const [currentIndex, setCurrentIndex] = useState(initialIndex);
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    setCurrentIndex(initialIndex);
  }, [initialIndex, isOpen]);

  useEffect(() => {
    dispatch(setCarouselOpen(isOpen));
  }, [isOpen, dispatch]);

  const handleClose = () => {
    dispatch(setCarouselOpen(false));
    onClose();
  };

  const handlePrevious = () => {
    if (images.length <= 1) return;
    setCurrentIndex((prev) => (prev === 0 ? images.length - 1 : prev - 1));
  };

  const handleNext = () => {
    if (images.length <= 1) return;
    setCurrentIndex((prev) => (prev === images.length - 1 ? 0 : prev + 1));
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === "ArrowLeft") {
      e.preventDefault();
      handlePrevious();
    }
    if (e.key === "ArrowRight") {
      e.preventDefault();
      handleNext();
    }
    if (e.key === "Escape") {
      e.preventDefault();
      onClose();
    }
  };

  const handleTouchStart = (e: React.TouchEvent) => {
    setTouchStart(e.targetTouches[0].clientX);
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const minSwipeDistance = 50;

    if (Math.abs(distance) < minSwipeDistance) return;

    if (distance > 0) {
      handleNext();
    } else {
      handlePrevious();
    }

    setTouchStart(0);
    setTouchEnd(0);
  };

  useEffect(() => {
    if (isOpen) {
      window.addEventListener("keydown", handleKeyDown);
      return () => window.removeEventListener("keydown", handleKeyDown);
    }
  }, [isOpen]);

  if (!images || images.length === 0) return null;

  return (
    <Dialog open={isOpen} onOpenChange={handleClose}>
      <DialogContent className="max-w-[100vw] h-[100vh] p-0 border-none bg-black/95 sm:max-w-[95vw] sm:h-[95vh]">
        <Button
          size="icon"
          className="absolute top-2 right-2 z-50 text-white/90"
          onClick={handleClose}
          aria-label="Close dialog"
        >
        </Button>
        <DialogTitle className="sr-only">Image Gallery</DialogTitle>
        
        <div className="relative flex items-center justify-center h-full w-full">
          <Button
            variant="ghost"
            size="icon"
            className="absolute left-2 sm:left-4 z-50 text-white/90 hover:text-white hover:bg-white/10"
            onClick={handlePrevious}
            aria-label="Previous image"
          >
            <ChevronLeft className="h-6 w-6 sm:h-8 sm:w-8" />
          </Button>

          <div 
            className="h-full w-full flex items-center justify-center p-2 sm:p-4"
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            <img
              key={currentIndex}
              src={images[currentIndex]}
              alt={`Image ${currentIndex + 1} of ${images.length}`}
              className="w-auto h-auto object-contain transition-opacity duration-200 rounded-lg"
              style={{
                maxWidth: '75vw',
                maxHeight: '50vh',
                width: 'auto',
                height: 'auto',
                objectFit: 'contain',
              }}
              loading="eager"
            />
          </div>

          <Button
            variant="ghost"
            size="icon"
            className="absolute right-2 sm:right-4 z-50 text-white/90 hover:text-white hover:bg-white/10"
            onClick={handleNext}
            aria-label="Next image"
          >
            <ChevronRight className="h-6 w-6 sm:h-8 sm:w-8" />
          </Button>
        </div>

        <div className="absolute bottom-4 left-0 right-0 flex justify-center items-center gap-4">
          <div className="bg-black/50 px-3 py-1 rounded-full">
            <span className="text-white/90 text-sm font-medium">
              {currentIndex + 1} / {images.length}
            </span>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
} 