"use client";

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useRouter } from "next/navigation";
import { RootState } from "@/lib/store";
import { handle401Error } from "@/lib/store";
import { Card } from "@/components/ui/card";
import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import { Heart } from "lucide-react";
import Link from "next/link";
import { ImageCarousel } from "@/components/image-carousel";

interface Post {
  id: number;
  creator: {
    id: number;
    nickname: string;
    picture: string;
  };
  pictures: string[];
  description: string;
  is_following: boolean;
  is_liked: boolean;
}

interface FeedResponse {
  posts: Post[];
  has_next_page: boolean;
}

export default function DashboardPage() {
  const router = useRouter();
  const { token, isAuthenticated } = useSelector((state: RootState) => state.auth);
  const [posts, setPosts] = useState<Post[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [likedPosts, setLikedPosts] = useState<Set<number>>(new Set());
  const [selectedImages, setSelectedImages] = useState<string[]>([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [isCarouselOpen, setIsCarouselOpen] = useState(false);

  useEffect(() => {
    if (!isAuthenticated || !token) {
      router.push("/login");
      return;
    }
  }, [isAuthenticated, token, router]);

  const fetchPosts = async (page: number) => {
    try {
      setIsLoading(true);
      const response = await fetch(
        `${process.env.NEXT_PUBLIC_API_DOMAIN}/feed/posts/?page=${page}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );

      if (!response.ok) {
        if (response.status === 401) {
          handle401Error();
          router.push("/login");
          return;
        }
        throw new Error("Failed to fetch posts");
      }

      const data: FeedResponse = await response.json();
      
      setPosts(prev => {
        // Create a map of existing posts
        const existingPosts = new Map(prev.map(post => [post.id, post]));
        
        // Add new posts, overwriting any existing ones with the same ID
        data.posts.forEach(post => {
          existingPosts.set(post.id, post);
        });
        
        // Convert back to array
        return Array.from(existingPosts.values());
      });
      
      setHasNextPage(data.has_next_page);
      
      // Initialize liked posts
      if (page === 1) {
        const initialLikedPosts = new Set(
          data.posts.filter(post => post.is_liked).map(post => post.id)
        );
        setLikedPosts(initialLikedPosts);
      } else {
        // Update liked posts for subsequent pages
        setLikedPosts(prev => {
          const newLikedPosts = new Set(prev);
          data.posts.forEach(post => {
            if (post.is_liked) {
              newLikedPosts.add(post.id);
            }
          });
          return newLikedPosts;
        });
      }
    } catch (error) {
      console.error("Error fetching posts:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isAuthenticated && token) {
      fetchPosts(1);
    }
  }, [isAuthenticated, token]);

  const handleLike = async (postId: number) => {
    try {
      const isLiked = likedPosts.has(postId);
      const newLikedPosts = new Set(likedPosts);
      
      // Optimistically update UI
      if (isLiked) {
        newLikedPosts.delete(postId);
      } else {
        newLikedPosts.add(postId);
      }
      setLikedPosts(newLikedPosts);

      const response = await fetch(
        `${process.env.NEXT_PUBLIC_API_DOMAIN}/feed/posts/${postId}/like/`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        if (response.status === 401) {
          handle401Error();
          router.push("/login");
          return;
        }
        // Revert on other errors
        setLikedPosts(likedPosts);
        throw new Error("Failed to update like status");
      }
    } catch (error) {
      console.error("Error updating like:", error);
      // Revert on error
      setLikedPosts(likedPosts);
    }
  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;
    if (scrollHeight - scrollTop <= clientHeight * 1.5 && !isLoading && hasNextPage) {
      setCurrentPage(prev => prev + 1);
      fetchPosts(currentPage + 1);
    }
  };

  // Create a flat array of all images from all posts
  const allImages = posts.flatMap(post => post.pictures);

  if (!isAuthenticated || !token) {
    return null;
  }

  return (
    <div className="glass-container rounded-lg bg-black/20 backdrop-blur-[40px] shadow-[0_8px_32px_rgba(0,0,0,0.2)] relative overflow-hidden h-[calc(100vh-1rem-1rem)] mt-4">
      <div 
        className="relative z-0 flex flex-col gap-4 overflow-y-auto h-full p-4 pt-24"
        onScroll={handleScroll}
      >
        <div className="grid w-full gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          {posts.map((post) => (
            <Card 
              key={`post-${post.id}`}
              className="group relative flex flex-col overflow-hidden rounded-lg shadow-[0_8px_30px_rgba(0,0,0,0.4)] transition-all duration-300 bg-black/85 hover:shadow-[0_14px_45px_rgba(0,0,0,0.5)] hover:ring-2 hover:ring-white/20"
            >
              <Link 
                href={`/creator/${post.creator.id}`}
                className="absolute top-0 left-0 right-0 z-20 flex items-center gap-2 p-2 bg-gradient-to-b from-black/75 via-black/40 to-transparent pb-6 transition-opacity hover:from-black/85"
              >
                <Avatar className="h-7 w-7 border border-white/20">
                  <AvatarImage src={post.creator.picture} />
                  <AvatarFallback>UN</AvatarFallback>
                </Avatar>
                <span className="text-xs font-medium text-white/90">{post.creator.nickname}</span>
              </Link>

              <div 
                className="relative aspect-square overflow-hidden cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  // Calculate the starting index for this image in the allImages array
                  const startIndex = posts.slice(0, posts.findIndex(p => p.id === post.id))
                    .reduce((acc, p) => acc + p.pictures.length, 0);
                  const imageIndex = startIndex + 0; // Always start with first image of the post
                  setSelectedImageIndex(imageIndex);
                  setIsCarouselOpen(true);
                }}
              >
                <img
                  src={post.pictures[0]}
                  alt={`Post by ${post.creator.nickname}`}
                  className="h-full w-full object-cover transition-transform duration-300 group-hover:scale-105"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/60 via-transparent to-transparent opacity-0 transition-opacity duration-300 group-hover:opacity-100" />
              </div>

              <div className="absolute bottom-0 left-0 right-0 flex h-9 items-end bg-gradient-to-t from-black/70 via-black/30 to-transparent px-2 pb-1 opacity-100 transition-opacity duration-300 md:h-10 md:items-center md:opacity-0 md:group-hover:opacity-100 md:px-4 md:pb-0">
                <Button 
                  variant="ghost"
                  size="sm"
                  className="h-6 -ml-1 gap-1 rounded-full px-1.5 text-white hover:bg-white/10 md:h-8 md:px-3 active:scale-90 transition-transform"
                  onClick={() => handleLike(post.id)}
                >
                  <Heart 
                    className={`h-3.5 w-3.5 transition-all ${likedPosts.has(post.id) ? "fill-white animate-like" : "text-white/90"}`}
                  />
                </Button>
              </div>
            </Card>
          ))}
        </div>
        
        {isLoading && (
          <div className="flex justify-center py-4">
            <div className="h-6 w-6 animate-spin rounded-full border-2 border-white border-t-transparent" />
          </div>
        )}
      </div>

      <ImageCarousel
        images={allImages}
        initialIndex={selectedImageIndex}
        isOpen={isCarouselOpen}
        onClose={() => setIsCarouselOpen(false)}
      />

      <style jsx global>{`
        @keyframes like {
          0%, 100% { transform: scale(1); }
          50% { transform: scale(1.2); }
        }
        .animate-like {
          animation: like 0.3s ease-in-out;
        }
      `}</style>
    </div>
  );
}
