import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ImageCarouselState {
  isOpen: boolean;
}

const initialState: ImageCarouselState = {
  isOpen: false,
};

const imageCarouselSlice = createSlice({
  name: 'imageCarousel',
  initialState,
  reducers: {
    setCarouselOpen: (state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload;
    },
  },
});

export const { setCarouselOpen } = imageCarouselSlice.actions;
export default imageCarouselSlice.reducer; 