"use client";

import { createContext, useContext, useEffect, useState } from "react";
import { NotificationToast, type Notification } from "./notification-toast";

interface NotificationContextType {
  notifications: Notification[];
  addNotification: (notification: Omit<Notification, "id" | "timestamp" | "read">) => void;
  markAsRead: (id: string) => void;
  removeNotification: (id: string) => void;
  unreadCount: number;
}

const NotificationContext = createContext<NotificationContextType | undefined>(undefined);

// Mock notifications for demo
const MOCK_NOTIFICATIONS = [
  {
    id: "1",
    creatorId: "creator1",
    creatorName: "Sarah Anderson",
    creatorAvatar: "https://api.dicebear.com/7.x/avataaars/svg?seed=1",
    message: "Hey! I just uploaded a new artwork. Check it out!",
    timestamp: new Date(Date.now() - 1000 * 60 * 5), // 5 minutes ago
    read: false,
  },
  {
    id: "2",
    creatorId: "creator2",
    creatorName: "Mike Johnson",
    creatorAvatar: "https://api.dicebear.com/7.x/avataaars/svg?seed=2",
    message: "Thanks for supporting my latest collection!",
    timestamp: new Date(Date.now() - 1000 * 60 * 15), // 15 minutes ago
    read: false,
  },
  {
    id: "3",
    creatorId: "creator3",
    creatorName: "Emma Davis",
    creatorAvatar: "https://api.dicebear.com/7.x/avataaars/svg?seed=3",
    message: "New live stream starting in 10 minutes!",
    timestamp: new Date(Date.now() - 1000 * 60 * 30), // 30 minutes ago
    read: false,
  },
];

export function NotificationProvider({ children }: { children: React.ReactNode }) {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [activeToast, setActiveToast] = useState<Notification | null>(null);

  // Calculate unread count
  const unreadCount = notifications.filter(n => !n.read).length;

  // Add a new notification
  const addNotification = (notification: Omit<Notification, "id" | "timestamp" | "read">) => {
    const newNotification: Notification = {
      ...notification,
      id: Math.random().toString(36).substring(7),
      timestamp: new Date(),
      read: false,
    };

    setNotifications(prev => [newNotification, ...prev]);
    setActiveToast(newNotification);
  };

  // Mark a notification as read
  const markAsRead = (id: string) => {
    setNotifications(prev =>
      prev.map(notification =>
        notification.id === id
          ? { ...notification, read: true }
          : notification
      )
    );
  };

  // Remove a notification
  const removeNotification = (id: string) => {
    setNotifications(prev =>
      prev.filter(notification => notification.id !== id)
    );
    if (activeToast?.id === id) {
      setActiveToast(null);
    }
  };

  // Load mock notifications on mount
  useEffect(() => {
    setNotifications(MOCK_NOTIFICATIONS);
    
    // Show a sample notification after a delay
    const timer = setTimeout(() => {
      return () => clearTimeout(timer);
      addNotification({
        creatorId: "creator4",
        creatorName: "Alex Turner",
        creatorAvatar: "https://api.dicebear.com/7.x/avataaars/svg?seed=4",
        message: "Just posted a new photo series! 📸✨",
      });
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        addNotification,
        markAsRead,
        removeNotification,
        unreadCount,
      }}
    >
      {children}
      {/* Render active toast */}
      {activeToast && (
        <NotificationToast
          notification={activeToast}
          onClose={(id) => {
            markAsRead(id);
            removeNotification(id);
          }}
        />
      )}
    </NotificationContext.Provider>
  );
}

export function useNotifications() {
  const context = useContext(NotificationContext);
  if (context === undefined) {
    throw new Error("useNotifications must be used within a NotificationProvider");
  }
  return context;
} 