"use client";

import { useState } from "react";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { motion, AnimatePresence } from "framer-motion";
import { SearchCommand } from "@/components/search/search-command";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";

export function TopBar() {
  return (
    <>
      <div className="sticky top-0 z-50 w-full border-b border-white/10 bg-black/20 backdrop-blur-xl">
        <div className="flex h-14 sm:h-16 items-center px-0 md:px-4">
          <div className="flex flex-1 items-center justify-between gap-4 px-4 md:px-0">
            {/* Logo */}
            <div className="flex items-center">
              <h1 className="text-2xl font-bold flex items-center m-0 hover:opacity-80 transition-opacity">
                <span className={cn("font-space-grotesk")}>
                  parad<span className="text-[#875fff]">AI</span>se
                </span>
              </h1>
            </div>
          </div>
        </div>
      </div>
    </>
  );
} 