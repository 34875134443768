"use client";

import { Home, Compass, Heart, MessageSquare, Users } from "lucide-react";
import { cn } from "@/lib/utils";
import { usePathname } from "next/navigation";
import Link from "next/link";
import { useSelector } from "react-redux";
import { RootState } from "@/lib/store";

const menuItems = [
  { icon: Home, label: "Home", href: "/" },
  // { icon: Compass, label: "Explore", href: "/explore" },
  { icon: Heart, label: "Liked", href: "/liked" },
  { icon: MessageSquare, label: "Chat", href: "/chat" },
  { icon: Users, label: "Following", href: "/following" },
];

export function MobileNav() {
  const pathname = usePathname();
  const isCarouselOpen = useSelector((state: RootState) => state.imageCarousel.isOpen);

  return (
    <div className={cn(
      "fixed bottom-0 left-0 right-0 z-[100] border-t border-white/[0.06] bg-black/75 backdrop-blur-lg backdrop-saturate-150 lg:hidden safe-area-bottom",
      isCarouselOpen && "pointer-events-none opacity-0"
    )}>
      <nav className="flex h-12 sm:h-14 items-center justify-around px-1 sm:px-2">
        {menuItems.map((item) => {
          const isActive = pathname === item.href;
          return (
            <Link
              key={item.href}
              href={item.href}
              className={cn(
                "flex flex-col items-center gap-0.5 sm:gap-1 rounded-lg px-2 sm:px-4 py-1.5 sm:py-2 transition-colors",
                isActive ? "text-white" : "text-zinc-400 hover:text-zinc-300"
              )}
            >
              <item.icon 
                className={cn(
                  "h-5 w-5 sm:h-6 sm:w-6 transition-all",
                  isActive && "scale-105"
                )} 
              />
              <span className={cn(
                "text-[10px] sm:text-xs font-medium transition-opacity",
                isActive ? "opacity-100" : "opacity-70"
              )}>
                {item.label}
              </span>
            </Link>
          );
        })}
      </nav>
    </div>
  );
} 