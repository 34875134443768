import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ActivSectionQueryState {
    activeSection: Number;
    activeSectionQuery: string;
}

const initialState: ActivSectionQueryState = {
    activeSection: 0,
    activeSectionQuery: '',
};

const activeSectionQuerySlice = createSlice({
    initialState,
    name: 'activeSectionQuery',
    reducers: {
        setActiveSection: (state, action: PayloadAction<Number>) => {
            state.activeSection = action.payload;
        },
        setActiveSectionQuery: (state, action: PayloadAction<string>) => {
            state.activeSectionQuery = action.payload;
        },
    },
});

export const { setActiveSectionQuery, setActiveSection } = activeSectionQuerySlice.actions;
export default activeSectionQuerySlice.reducer; 