"use client"

import {
  Home,
  Compass,
  Heart,
  MessageSquare,
  Users,
  Settings,
} from "lucide-react"
import {
  SidebarMenu,
  SidebarMenuItem,
  SidebarMenuButton,
  SidebarSeparator,
} from "./ui/sidebar"
import Link from "next/link"
import { usePathname } from "next/navigation"

const menuItems = [
  { icon: Home, label: "Home", href: "/" },
  { type: "separator" },
  // { icon: Compass, label: "Explore", href: "/explore" },
  { icon: Heart, label: "Liked", href: "/liked" },
  { icon: MessageSquare, label: "Chat", href: "/chat" },
  { icon: Users, label: "Following", href: "/following" },
  // { type: "separator" },
  // { icon: Settings, label: "Settings", href: "/settings" },
]

export function NavMain() {
  const pathname = usePathname()

  return (
    <SidebarMenu>
      {menuItems.map((item, index) =>
        'type' in item ? (
          <SidebarSeparator key={index} className="bg-zinc-800" />
        ) : (
          <SidebarMenuItem key={item.href}>
            <SidebarMenuButton 
              asChild 
              tooltip={item.label} 
              isActive={pathname === item.href}
              className="text-zinc-400 hover:bg-zinc-800/50 hover:text-white data-[active=true]:bg-zinc-800/30 data-[active=true]:text-white"
            >
              <Link href={item.href}>
                <item.icon className="size-4" />
                <span>{item.label}</span>
              </Link>
            </SidebarMenuButton>
          </SidebarMenuItem>
        )
      )}
    </SidebarMenu>
  )
}
